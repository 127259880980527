import React, { useRef } from "react"
import { graphql } from "gatsby"
import "./hero.module.scss"

import { HeroContent } from "../HeroContent"
import { Banner } from "../_types/hero-types"
import { HeroContentBaseClassName } from "../_types/hero-classes-types"
import { useHeroComponents } from "../_hooks/useHeroComponents"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { HeroForm } from "components/forms/HeroForm"
import { useHeroForm } from "../_hooks/useHeroForm"
import { TrackingNumberModalProvider } from "contexts/TrackingNumberModalContext"
import { TrackingNumberModalContainer } from "components/modal/TrackingNumberModal/TrackingNumberModalContainer"

interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    components,
    heading,
    sub_heading,
    tagline,
  } = banner

  const { form, hasForm } = useHeroComponents(components?.modular_content)

  const prefixModifier = hasForm ? `fr-hero--with-form` : "",
    backgroundImageUrl = background_image?.value[0]?.url,
    bannerContentBaseClassName =
      `fr-hero__banner-content` as HeroContentBaseClassName,
    contentClassName = hasForm
      ? (`${bannerContentBaseClassName}--with-form` as HeroContentBaseClassName)
      : (`${bannerContentBaseClassName}` as HeroContentBaseClassName)

  const formContainerRef = useRef<HTMLDivElement>(null),
    heroContainerRef = useRef<HTMLDivElement>(null)

  const { style } = useHeroForm({
    formHeight: formContainerRef.current?.clientHeight,
    heroHeight: heroContainerRef.current?.clientHeight,
  })

  return (
    <div className={hasForm ? "fr-hero-container" : ""} style={style}>
      <BannerWrapper
        backgroundImageUrl={backgroundImageUrl}
        containerSize="large"
        contentClassName={contentClassName}
        prefix="fr-hero"
        prefixModifierClassName={prefixModifier}
        containerRef={heroContainerRef}
      >
        <TrackingNumberModalProvider>
          <TrackingNumberModalContainer>
            <HeroContent
              baseClassName="fr-hero"
              buttons={buttons?.value}
              hasAlwaysOfficeNumber
              heading={heading?.value}
              subHeading={sub_heading}
              tagline={tagline}
            />
          </TrackingNumberModalContainer>
        </TrackingNumberModalProvider>
      </BannerWrapper>
      {hasForm && (
        <HeroForm
          containerSize="large"
          form={form}
          prefix="fr-hero-form"
          containerRef={formContainerRef}
        />
      )}
    </div>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
        type
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
      video_background_url {
        value
      }
    }
  }
`
