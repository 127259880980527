import React, { useEffect, useState } from "react"
import { breakpointsList } from "assets/helper/breakpoints"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface UseHeroFormParams {
  formHeight?: number
  heroHeight?: number
}

const MIN_PADDING_BOTTOM = 34

export const useHeroForm = ({ formHeight, heroHeight }: UseHeroFormParams) => {
  const { size } = useWindowDimensions()

  const [paddingBottomOnLaptop, setPaddingBottomOnLaptop] = useState(() => {
    if (formHeight && heroHeight) {
      const paddingBottom = formHeight - heroHeight
      return paddingBottom
    }

    return MIN_PADDING_BOTTOM
  })

  useEffect(() => {
    if (size < breakpointsList.laptop) return

    if (formHeight && heroHeight) {
      const paddingBottom = formHeight - heroHeight

      if (paddingBottomOnLaptop === paddingBottom) return

      setPaddingBottomOnLaptop(paddingBottom)
    }
  }, [size, formHeight, heroHeight, paddingBottomOnLaptop])

  const style: Record<string, string> = {
    "--padding-bottom-laptop": `${paddingBottomOnLaptop}px`,
  }

  return {
    style,
  }
}
