import React from "react"
import { FormBuilder } from "components/forms/FormBuilder"
import { KontentItemFormElements } from "components/forms/_types/formBuilder-types"
import "./hero-form.module.scss"

const CONTAINER_SIZE = {
  default: "fr-container",
  large: "fr-container fr-container--large",
  medium: "fr-container fr-container--medium",
  small: "fr-container fr-container--small",
  none: "",
}

type ContainerSize = keyof typeof CONTAINER_SIZE

interface HeroFormProps {
  containerSize?: ContainerSize
  form: KontentItemFormElements
  prefix: string
  containerRef?: React.RefObject<HTMLDivElement>
}

export const HeroForm = ({
  containerSize = "none",
  form,
  prefix,
  containerRef,
}: HeroFormProps) => {
  const {
    background_image,
    custom_id,
    fields,
    heading,
    is_boxed_layout,
    submission_message_body,
    submission_message_heading,
    submit_button_text,
  } = form

  const backgroundImage = background_image?.value[0],
    isBoxed = is_boxed_layout?.value[0]?.codename === "yes",
    classNames = `${CONTAINER_SIZE[containerSize]} ${prefix}__container`

  return (
    <div ref={containerRef} className={classNames}>
      <FormBuilder
        backgroundImage={backgroundImage}
        ctaText={submit_button_text?.value}
        customId={custom_id?.value || "hero-banner-form"}
        fields={fields}
        formGridStyle="form-grid-2--full-width"
        heading={heading?.value}
        isBoxed
        submissionMessageBody={submission_message_body?.value}
        submissionMessageHeading={submission_message_heading?.value}
      />
    </div>
  )
}
